import React from "react"

import { rhythm } from "../utils/typography"
import Head from './head'

const Layout = ({ location, title, description, children }) => {

  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1)} ${rhythm(3 / 4)}`,
      }}
    >
      <Head
        description={description}
        title={title}
      />
      <main>{children}</main>
    </div>
  )
}

export default Layout
