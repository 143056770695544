import Typography from "typography"

const typography = new Typography({
  scaleRatio: 1.3,
  headerFontFamily: ['Noto Sans JP', 'sans-serif'],
  headerColor: '#eb6317',
  bodyFontFamily: ['Noto Sans JP', 'sans-serif'],
  googleFonts: [
    {
      name: 'Noto Sans JP',
      styles: ['900'],
    },
    {
      name: 'Noto Sans JP',
      styles: ['400'],
    },
  ],
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
