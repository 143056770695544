import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import BackgroundImage from 'gatsby-background-image'

const Head = ({description, title}) => {
  const data = useStaticQuery(graphql`
  query HeadQuery {
    title_bg: file(absolutePath: { regex: "/bg.png/" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  const imageData = data.title_bg.childImageSharp.fluid

  return (
    <header>
      <div
        style={{
          paddingBottom: 5,
        }}
      >
        {description}
      </div>
      <BackgroundImage
        Tag="section"
        fluid={imageData}
      >
        <div
          style={{
            height: 180,
          }}
        />
      </BackgroundImage>
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1),
          marginTop: 0,
          padding: 10,
          color: '#fff',
          backgroundColor: '#000',
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    </header>
  )
}

export default Head
